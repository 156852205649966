import React from 'react';
import './App.css';
import emailjs from 'emailjs-com';
import backgroundWall from './assets/backgroundwall.png';

const App = () => {
    const handleFormSubmit = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_ona2l2e', // Replace with your EmailJS Service ID
                'template_dzrou1r', // Replace with your EmailJS Template ID
                e.target,
                'HyhDtPa2bXrPU7bUS' // Replace with your EmailJS User ID
            )
            .then(
                () => {
                    alert('Thank you for signing up!');
                },
                (error) => {
                    alert('Failed to send email. Please try again.');
                    console.error(error);
                }
            );
        e.target.reset(); // Clear the form after submission
    };

    return (
        <div
            className="background"
            style={{
                backgroundImage: `url(${backgroundWall})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100%',
            }}
        >
            <div className="content">
                <h1>Coming Soon</h1>
                <p>Sign up to be notified when we launch!</p>
                <form className="newsletter-form" onSubmit={handleFormSubmit}>
                    <input
                        type="email"
                        name="email" // Important for EmailJS to capture the email
                        placeholder="Enter your email"
                        required
                    />
                    <button type="submit">Notify Me</button>
                </form>
            </div>
        </div>
    );
};

export default App;
